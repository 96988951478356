import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../../components/issueIndex";
export const _frontmatter = {
  "title": "Volume 4, Issue 1"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Published: January, 2020`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./president-letter/"
        }}>{`President's letter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./editorial/"
        }}>{`Editorial`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./badakere-rao/"
        }}>{`Dr BC Rao`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./child-development-assessment-tools/"
        }}>{`Child Development Assessment Tools`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./patient-with-fever/"
        }}>{`Patient With Fever`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./tale-of-continued-diagnosis/"
        }}>{`A Tale of Continued Diagnosis`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./urine-tuberculosis-test/"
        }}>{`Urine Tuberculosis Test`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./masala/"
        }}>{`Masala`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./rural-sensitization-program/"
        }}>{`Rural Sensitization Program`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./travel-fellowship-in-primary-health-care/"
        }}>{`A journey towards health for all - a travel fellowship in primary health care`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      